import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';
import cloudinary from '../utils/cdn';
import LazyImage from '../components/Image/LazyImage';
import Container from '../components/Layout/Container';

const StyledArticle = styled.article`
  margin: 1.5rem 0;

  img {
    text-align: center;
    display: inline;
    margin-bottom: 0.5rem;
  }

  h1 {
    font-weight: 700;
    margin: 0 0 16px;
  }

  h3 {
    margin: 1rem 0;
  }

  p {
    text-align: justify;
    margin-bottom: 25px;
  }

  .steps,
  .ingredients {
    margin-bottom: 25px;

    li {
      margin: 0 0 17px 37px;
      list-style-type: disc;
    }
  }

  .slider-step {
    padding: 1rem;
    color: var(--color-label-fg);
    background: var(--color-label-bg);
    border-radius: 5px;
    margin-bottom: 1rem;
  }
`;

function Basics({ basics }) {
  return (
    <div className="mt-1">
      <h4>Recetas base que vas a necesitar</h4>
      <ol className="recipe__basics">
        {basics.map((basic) => (
          <li key={basic.slug}>
            <Link to={`/recetas/${basic.slug}`}>{basic.name}</Link>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default function Recipe({ data }) {
  const { recipe } = data;
  const images = recipe.images.filter((image) => image.type === 1);
  const portrait = images[0];

  const base = images.slice(1);

  const stepImages = recipe.images
    .filter((image) => image.type === 2)
    .sort((a, b) => a.order > b.order);

  return (
    <Container>
      <StyledArticle>
        <SEO title={recipe.title}>
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`/receta/${recipe.slug}`} />
          <meta property="og:image" content={cloudinary(portrait.name)} />
        </SEO>

        <h1>{recipe.title}</h1>
        <LazyImage name={portrait.name} alt={portrait.title} />

        <p>{recipe.description}</p>

        {base.map((image, index) => (
          <LazyImage key={index} name={image.name} alt={image.title} />
        ))}

        {recipe.basics ? <Basics basics={recipe.basics} /> : null}

        <h2>Ingredientes</h2>
        <ul className="ingredients">
          {recipe.ingredients.map((ingredient) => (
            <li key={ingredient}>{ingredient}</li>
          ))}
        </ul>

        <h2>Preparación</h2>
        <ol className="steps">
          {recipe.steps.map((step) => (
            <li key={step.name}>{step.name}</li>
          ))}
        </ol>

        <h2>Paso a paso</h2>
        <ol>
          {stepImages.map((image) => (
            <li key={image.name}>
              <div className="slider-step">
                <span className="order">
                  {`${image.order}. `}
                </span>
                {image.description}
              </div>
              <LazyImage name={image.name} alt={image.title} />
            </li>
          ))}
        </ol>
      </StyledArticle>
    </Container>
  );
}

export const query = graphql`
  query($slug: String!) {
    recipe: recipesJson(slug: { eq: $slug }) {
      slug
      title
      description
      categories
      basics {
        name
        slug
      }
      ingredients
      steps {
        name
        number
      }
      price
      people
      time
      images {
        name
        title
        description
        type
        order
      }
      created_at
    }
  }
`;
